import React from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

//Component Import
import TitleUnderlined from '../components/TitleUnderlined';
import convertHtml from '../utils/convertHtml';
import DownArrow from '../images/assets/rightArrow.svg';
import Squiggle from '../components/squiggle';
import linkStripper from '../utils/link-stripper';

const ExpertiseHeaderTwoContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const ExpertiseHeaderTwoContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  position: relative;
  height: calc(100vh - 120px);
  max-height: 1000px;
  min-height: 600px;
  p {
    margin: 0;
  }
  max-width: 2000px;
  width: 100%;
  overflow: hidden;
`;

const Subtitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #404040;
  margin-top: 50px;
  margin-bottom: 175px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
`;

const CenterContent = styled.div`
  max-width: 500px;
  position: relative;
  display: flex;
  align-items: center;
`;

const TextContent = styled(motion.div)``;

const LeftContent = styled.div`
  position: absolute;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
`;

const RightContent = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  margin-bottom: 150px;
`;

const LeftImage = styled(motion.img)`
  max-height: 550px;
  margin-top: -200px;
  @media (max-width: 1340px) {
    max-height: 350px;
  }
  @media (max-width: 1050px) {
    display: none;
  }
`;

const RightImage = styled(motion.img)`
  max-height: 470px;
  @media (max-width: 1340px) {
    max-height: 370px;
  }
  @media (max-width: 1050px) {
    display: none;
  }
`;

const CtaContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  margin-bottom: 50px;
  cursor: pointer;
`;

const CtaContainerInner = styled(motion.a)``;

const ArrowContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  transform: rotate(90deg);
`;

const CtaText = styled(motion.div)`
  font-weight: bold;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #404040 !important;
`;

const SquiggleContainer = styled.div`
  position: absolute;
  z-index: 2;
  left: -10vw;
  mix-blend-mode: multiply;
  margin-top: 140px;
  @media (max-width: 800px) {
    left: -30vw;
  }
  @media (max-width: 600px) {
    left: -50vw;
  }
`;

const LeftImageContainer = styled(motion.div)`
  height: 565px;
  width: 365px;
  margin-top: -200px;
  @media (max-width: 1225px) {
    height: 400px;
    width: 225px;
  }
  @media (max-width: 1025px) {
    display: none;
  }
`;

const RightImageContainer = styled(motion.div)`
  width: 340px;
  height: 475px;
  @media (max-width: 1225px) {
    width: 240px;
    height: 435px;
  }
  @media (max-width: 1025px) {
    display: none;
  }
`;

const TitleContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ExpertiseHeaderTwo = ({
  header_text,
  subtitle_text,
  cta_text,
  cta_link,
  cta_text_color,
  cta_target,
  theme_color,
  left_aligned_image,
  right_aligned_image,
}) => {
  const [active, setActive] = React.useState(false);
  return (
    <ExpertiseHeaderTwoContainerOuter>
      <ExpertiseHeaderTwoContainer>
        <LeftContent>
          <LeftImage
            src={left_aligned_image}
            animate={{ opacity: 1, translateX: 0 }}
            initial={{ opacity: 0, translateX: -50 }}
            transition={{ duration: 0.5 }}
          />
        </LeftContent>
        <CenterContent>
          <TextContent
            animate={{ opacity: 1, translateY: 0 }}
            initial={{ opacity: 0, translateY: -30 }}
            transition={{ duration: 0.4 }}
          >
            <TitleContainer>
              <TitleUnderlined
                underlineColor={theme_color}
                textColor={cta_text_color}
                fontSize={'72px'}
                lineHeight={'80px'}
                mobileFontSize={'40px'}
                mobileLineHeight={'40px'}
                titleText={'<span>' + header_text.text + '</span>'}
                h1Mode={true}
              />
            </TitleContainer>
            <Subtitle dangerouslySetInnerHTML={convertHtml(subtitle_text)} />
          </TextContent>
          <CtaContainer
            animate={{ opacity: 1, translateY: 0 }}
            initial={{ opacity: 0, translateY: -50 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <CtaContainerInner
              onHoverStart={() => setActive(!active)}
              onHoverEnd={() => setActive(!active)}
              href={linkStripper(cta_link)}
              target={cta_target}
            >
              <CtaText
                animate={active ? { color: theme_color } : { color: '#404040' }}
              >
                {cta_text}
              </CtaText>

              <ArrowContainer
                animate={
                  active
                    ? { translateY: '5px', rotate: '90deg' }
                    : { translateY: '0px', rotate: '90deg' }
                }
              >
                <DownArrow fill="#404040" />
              </ArrowContainer>
            </CtaContainerInner>
          </CtaContainer>
        </CenterContent>
        <RightContent>
          <RightImage
            src={right_aligned_image}
            animate={{ opacity: 1, translateX: 0 }}
            initial={{ opacity: 0, translateX: 50 }}
            transition={{ duration: 0.7, delay: 0.2 }}
          />
        </RightContent>
      </ExpertiseHeaderTwoContainer>
    </ExpertiseHeaderTwoContainerOuter>
  );
};

export default ExpertiseHeaderTwo;
